import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { DeviceBehaviour, DeviceBehaviourAdoptSiteSpecific, DeviceBehaviourSetting, DeviceBehaviourSettingType, DeviceBehaviourType, DeviceBehaviourUserLogin, DeviceBehaviourUserLogout, IDeviceBehaviourSelect } from "vigil-datamodel";
import { InputSelect, OptionSelect } from "../../../components/input_select";
import { deviceBehaviourOptions } from "./helper_device_behaviour";
import { BehaviourUserLoginComponent } from "./behaviour_user_login";
import { BehaviourUserLogoutComponent } from "./behaviour_user_logout";
import { BehaviourAdoptSiteSpecificComponent } from "./behaviour_adopt_site_specific_device_behaviour";

interface ModalDeviceBehaviourCreateProps extends ModalProps {
  onSubmit?: (deviceBehaviour: IDeviceBehaviourSelect) => Promise<void>;
}

export const ModalDeviceBehaviourCreate: React.FC<ModalDeviceBehaviourCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateName, setName] = useState<string>('');
  const [stateSettingType, setSettingType] = useState<DeviceBehaviourSettingType>(DeviceBehaviourSettingType.DeviceSpecific);
  const [stateSetting, setSetting] = useState<DeviceBehaviourSetting>({ type: DeviceBehaviourSettingType.DeviceSpecific });
  const [stateBehaviours, setBehaviours] = useState<DeviceBehaviour[]>([]);
  const [stateAddedBehaviourOptions, setAddedBehaviourOptions] = useState<OptionSelect[]>([]);
  const [stateValidateBehaviours, setValidateBehaviours] = useState<string[]>([]);

  /* DB Functions */
  async function createDeviceBehaviour() {
    if (!organization.data) return;
    try {
      setError('');
      setSubmitLoading(true);
      const deviceBehaviour = await vigil.functions.createOneOnOrganization({
        type: 'deviceBehaviours',
        uuidOrganization: organization.data.uuid,
        data: {
          uuidOrganization: organization.data.uuid,
          name: stateName,
          settingType: stateSettingType,
          setting: stateSetting,
          behaviours: stateBehaviours,
        }
      });
      props.onSubmit && await props.onSubmit(deviceBehaviour);
      props.toggle();
      resetState();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Device behaviour name', 2, 30)]);
  }

  function validateBeaconPerimeterRadius() {
    if (stateSetting.type !== DeviceBehaviourSettingType.SiteSpecific) return [];
    if (!stateSetting.beaconPerimeterRadius) return [];
    return validate(stateSetting.beaconPerimeterRadius, [VALIDATORS.numberBetween('Beacon Radius Trigger', 5, 1000)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Device behaviour name is required') }
    return [
      ...required,
      ...validateName(),
      ...validateBeaconPerimeterRadius(),
      ...stateValidateBehaviours,
    ];
  }

  /* Functions */
  function settingTypeOptions() {
    return Object.values(DeviceBehaviourSettingType).map(type => ({ value: type, label: type.replace('Specific', ' Specific').toUpperCase() }));
  }

  function resetState() {
    setName('');
    setSettingType(DeviceBehaviourSettingType.DeviceSpecific);
    setSetting({ type: DeviceBehaviourSettingType.DeviceSpecific });
    setBehaviours([]);
  }

  /* UI Updates */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setName(event.target.value);
  }

  function onChangeSettingType(event: React.ChangeEvent<HTMLSelectElement>) {
    const type = event.target.value as DeviceBehaviourSettingType;
    setError('');
    setSettingType(type);
    if (type === DeviceBehaviourSettingType.SiteSpecific) {
      setSetting({ type: type, beaconPerimeterRadius: 5 });
    } else {
      setSetting({ type: type });
    }
  }

  function onChangeBeaconPerimeterRadius(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    if (event.target.value === '') {
      setSetting({ type: DeviceBehaviourSettingType.SiteSpecific, beaconPerimeterRadius: 0 });
    } else {
      setSetting({ type: DeviceBehaviourSettingType.SiteSpecific, beaconPerimeterRadius: parseFloat(event.target.value) });
    }
  }

  function addBehaviourOption() {
    setAddedBehaviourOptions([...stateAddedBehaviourOptions, deviceBehaviourOptions[0]]);
  }

  function removeBehaviourOption(index: number) {
    const newAddedBehaviours = [...stateAddedBehaviourOptions];
    newAddedBehaviours.splice(index, 1);
    setAddedBehaviourOptions(newAddedBehaviours);
  }

  function onChangeBehaviourOption(index: number, event: React.ChangeEvent<HTMLSelectElement>) {
    const deviceBehaviourOption = deviceBehaviourOptions.find(option => option.value === event.target.value);
    if (deviceBehaviourOption) {
      const newAddedBehaviours = [...stateAddedBehaviourOptions];
      newAddedBehaviours[index] = deviceBehaviourOption;
      setAddedBehaviourOptions(newAddedBehaviours);
    }
  }

  /* Behaviour Login */
  function onChangeBehaviour(index: number, behaviour: DeviceBehaviour) {
    const newBehaviours = [...stateBehaviours];
    newBehaviours[index] = behaviour;
    setBehaviours(newBehaviours);
  }

  function onChangeBehaviourValidation(index: number, errors: string[]) {
    const newValidateBehaviours = [...stateValidateBehaviours];
    newValidateBehaviours[index] = errors.join(', ');
    setValidateBehaviours(newValidateBehaviours.filter((error) => error.length > 0));
  }

  return (
    <Modal className='w-192' isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Device Behaviour</h3>
      <div className="flex flex-wrap">
        <InputText className='w-60 mr-2' labelText='Device behaviour name' value={stateName} onChange={onChangeName} errors={validateName()} > </InputText>
        <InputSelect className='w-60 mr-2' labelText='Setting type' value={stateSettingType} onChange={onChangeSettingType} errors={[]} options={settingTypeOptions()} />
        {stateSetting.type === DeviceBehaviourSettingType.SiteSpecific &&
          <InputText className='w-60 mr-2' labelText='Beacon Radius Trigger ⓘ' labelTooltip="The radius in meters that triggers the device behaviour override"
            value={stateSetting.beaconPerimeterRadius} onChange={onChangeBeaconPerimeterRadius} errors={[]} />}
      </div>
      <div className="overflow-y-auto">
        <h3 className="font-bold text-sm mb-2">Behaviours</h3>
        {stateAddedBehaviourOptions.map((behaviour, index) => {
          return (
            <div key={index + stateAddedBehaviourOptions.length} className="mb-2 mr-4 bg-base-200 p-1 rounded-xl relative">
              <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => removeBehaviourOption(index)}>✕</button>
              <InputSelect className="w-72 mb-2 mr-4" errors={[]} labelText="Behaviour Option" value={behaviour.value} options={deviceBehaviourOptions} onChange={(event) => onChangeBehaviourOption(index, event)} />
              {behaviour.value === DeviceBehaviourType.UserLogin && <BehaviourUserLoginComponent
                behaviour={stateBehaviours[index] as DeviceBehaviourUserLogin}
                setBehaviour={(behaviour) => onChangeBehaviour(index, behaviour)}
                setValidateForm={(errors) => onChangeBehaviourValidation(index, errors)} />}
              {behaviour.value === DeviceBehaviourType.UserLogout && <BehaviourUserLogoutComponent
                behaviour={stateBehaviours[index] as DeviceBehaviourUserLogout}
                setBehaviour={(behaviour) => onChangeBehaviour(index, behaviour)}
                setValidateForm={(errors) => onChangeBehaviourValidation(index, errors)} />}
              {behaviour.value === DeviceBehaviourType.AdoptSiteSpecificDeviceBehaviour && <BehaviourAdoptSiteSpecificComponent
                behaviour={stateBehaviours[index] as DeviceBehaviourAdoptSiteSpecific}
                setBehaviour={(behaviour) => onChangeBehaviour(index, behaviour)}
                setValidateForm={(errors) => onChangeBehaviourValidation(index, errors)} />}
            </div>
          )
        })}
        <div className="flex mb-2">
          <InputButton text='+ Add Behaviour' type='btn-primary' onClick={addBehaviourOption}></InputButton>
        </div>
      </div>
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createDeviceBehaviour()}></InputButton>
      </div>
    </Modal>
  )
}