import 'animate.css'
import './index.css'
import './charts'
import ReactDOM from 'react-dom/client'
import { ProviderVigilClient } from './providers/provider_vigil_client'
import { ProviderTheme } from './providers/provider_theme'
import { Toast } from './providers/provider_toast'
import { ProviderSession } from './providers/provider_session'
import { Environment } from './environment'
import { RouterView } from './router/router'

// Clean up window.env
const dirty = window.env || {}
const clean: Record<string, any> = {}
for(const key of Object.keys(dirty)) {
  if (dirty[key] === undefined) {
    continue
  }
  clean[key] = dirty[key]
}
window.env = clean

console.log(window.env)
console.log(import.meta.env)

const env = {
  ...import.meta.env,
  SERVICE_BACKEND_HOST_URL: import.meta.env.VITE_SERVICE_BACKEND_HOST_URL,
  SERVICE_MAP_HOST_URL: import.meta.env.VITE_SERVICE_MAP_HOST_URL,
  ...window.env
}

Environment.init(env);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ProviderVigilClient>
    <ProviderSession>
      <ProviderTheme>
        <Toast>
          <RouterView />
        </Toast>
      </ProviderTheme>
    </ProviderSession>
  </ProviderVigilClient>
)


// import * as Sentry from "@sentry/react";
// // TODO: Disable sentry while in development mode. use new SENTRY_DISABLED env var
// Sentry.init({
//   dsn: "https://04202742f6919c492e7af48f39c391c5@o1346134.ingest.us.sentry.io/4506942810292224",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
