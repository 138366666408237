import { IBaseSyncable } from "tt-coms";
import { customJsonColumn, schemaBase } from "./bean_base";
import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { relations } from "drizzle-orm";
import { linkOrganizationEvents } from "./bean_link_organization_event";
import { eventInstances } from "./bean_event_instance";

// EventTrigger
export enum EventTriggerType {
  Scheduled = 'scheduled',
  Geofence = 'geofence',
}

export type BaseEventTrigger = {
  type: EventTriggerType;
  enabled: boolean;
}

export type EventTriggerSchedule = BaseEventTrigger & {
  type: EventTriggerType.Scheduled;
  enabled: boolean;
  creationCron: string;
  creationLifetimeMS: number;
  eventCron: string;
  eventLifetimeMS: number;
}

export type EventTriggerGeoFence = BaseEventTrigger & {
  type: EventTriggerType.Geofence;
  enabled: boolean;
  creationLattitude: string;
  creationLongitude: number;
  creationRadius: number;
  eventCron: string;
  eventLifetimeMS: number;
}

export type EventTrigger = EventTriggerSchedule | EventTriggerGeoFence;

// EventConfig
export enum EventConfigType {
  Patrol = 'patrol',
  Script = 'script',
}

export type BaseEventConfig = {
  type: EventConfigType;
  enabled: boolean;
}

export interface IPatrolDuty {
  on: number,
  off: number,
  failedPatrolPercentage?: number,
  startRestOnCompletePatrol?: boolean,
}

export interface IPatrolSite {
  uuid: string,
  // I can see how we might have some config options per site later
}

export interface IPatrolMember {
  uuid: string,
  type: 'device' | 'user' | 'role',
}

export type EventConfigPatrol = BaseEventConfig & {
  type: EventConfigType.Patrol;
  enabled: boolean;
  sites: IPatrolSite[],
  members: IPatrolMember[],
  beaconCheckin: boolean,
  geofenceCheckinRadius?: number,
  duty?: IPatrolDuty,
  lateStartDuration?: number,
}

export type EventConfigScript = BaseEventConfig & {
  type: EventConfigType.Script;
  enabled: boolean;
  script: string,
}

export type EventConfig = EventConfigPatrol | EventConfigScript;

export interface IEvent extends IBaseSyncable {
  name: string;
  triggers: EventTrigger[];
  configs: EventConfig[];
  enabled: boolean;
}

export const events = sqliteTable('events', {
  ...schemaBase(),
  name: text('name').notNull(),
  triggers: customJsonColumn<EventTrigger[]>('triggers').notNull(),
  configs: customJsonColumn<EventConfig[]>('configs').notNull(),
  enabled: integer('enabled').notNull(),
});

export const eventsRelations = relations(events, ({ many }) => ({
  eventsToOrganizations: many(linkOrganizationEvents),
  eventInstances: many(eventInstances),
}));
