import { IBaseSyncable } from "tt-coms";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { linkDeviceBehaviourDevices } from "./bean_link_device_behaviour_device";
import { linkDeviceBehaviourSites } from "./bean_link_device_behaviour_site";
import { relations } from "drizzle-orm";
import { deviceBehaviourInstances } from "./bean_device_behaviour_instance";
import { organizations } from "./bean_organization";

export enum DeviceBehaviourSettingType {
  DeviceSpecific = 'deviceSpecific',
  SiteSpecific = 'siteSpecific',
}

export type BaseDeviceBehaviourSetting = {
  type: DeviceBehaviourSettingType;
}

export type DeviceBehaviourSettingDeviceSpecific = BaseDeviceBehaviourSetting & {
  type: DeviceBehaviourSettingType.DeviceSpecific;
}

/**
 * Device behaviour settings specific to a site
 * @property type - Must be SiteSpecific
 * @property beaconPerimeterRadius - Distance in meters from beacon that will trigger site specific behaviour to override device specific behaviour, if AdoptSiteSpecificDeviceBehaviour is enabled
 */
export type DeviceBehaviourSettingSiteSpecific = BaseDeviceBehaviourSetting & {
  type: DeviceBehaviourSettingType.SiteSpecific;
  beaconPerimeterRadius: number;
}

export type DeviceBehaviourSetting = DeviceBehaviourSettingDeviceSpecific | DeviceBehaviourSettingSiteSpecific;

export enum DeviceBehaviourType {
  UserLogin = 'userLogin',
  UserLogout = 'userLogout',
  AdoptSiteSpecificDeviceBehaviour = 'adoptSiteSpecificDeviceBehaviour',
}

export type BaseDeviceBehaviour = {
  type: DeviceBehaviourType;
}

export type DeviceBehaviourUserLogin = BaseDeviceBehaviour & {
  type: DeviceBehaviourType.UserLogin;
  startCron: string;
  durationMS: number;
}

export type DeviceBehaviourUserLogout = BaseDeviceBehaviour & {
  type: DeviceBehaviourType.UserLogout;
  startCron: string;
  durationMS: number;
}

// If link is siteSpecific, then this behaviour will not be visible
export type DeviceBehaviourAdoptSiteSpecific = BaseDeviceBehaviour & {
  type: DeviceBehaviourType.AdoptSiteSpecificDeviceBehaviour;
  enabled: boolean;
}

export type DeviceBehaviour = DeviceBehaviourUserLogin | DeviceBehaviourUserLogout | DeviceBehaviourAdoptSiteSpecific;

export interface IDeviceBehaviour extends IBaseSyncable {
  uuidOrganization: string;
  name: string;
  settingType: DeviceBehaviourSettingType;
  setting: DeviceBehaviourSetting;
  behaviours: DeviceBehaviour[];
}

export const deviceBehaviours = sqliteTable('device_behaviours', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  settingType: text('setting_type', { enum: Object.values(DeviceBehaviourSettingType) as [DeviceBehaviourSettingType] }).notNull(),
  setting: customJsonColumn<DeviceBehaviourSetting>('setting').notNull(),
  behaviours: customJsonColumn<DeviceBehaviour[]>('behaviours').notNull(),
});

export const deviceBehavioursRelations = relations(deviceBehaviours, ({ one, many }) => ({
  organization: one(organizations, {
    fields: [deviceBehaviours.uuidOrganization],
    references: [organizations.uuid],
  }),
  deviceBehavioursToDevices: many(linkDeviceBehaviourDevices),
  deviceBehavioursToSites: many(linkDeviceBehaviourSites),
  deviceBehaviourInstances: many(deviceBehaviourInstances),
}));
