import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { sites } from "./bean_site";
import { deviceBehaviours } from "./bean_device_behaviour";

export const linkDeviceBehaviourSites = sqliteTable('link_device_behaviour_sites', {
  ...schemaBase(),
  uuidSite: text('uuid_site').notNull().references(() => sites.uuid),
  uuidDeviceBehaviour: text('uuid_device_behaviour').notNull().references(() => deviceBehaviours.uuid),
});

export const linkDeviceBehaviourSitesRelations = relations(linkDeviceBehaviourSites, ({ one }) => ({
  site: one(sites, {
    fields: [linkDeviceBehaviourSites.uuidSite],
    references: [sites.uuid],
  }),
  deviceBehaviour: one(deviceBehaviours, {
    fields: [linkDeviceBehaviourSites.uuidDeviceBehaviour],
    references: [deviceBehaviours.uuid],
  }),
}));
