import { FC, useContext, useState } from 'react';
import { useModal } from '../../hooks/hook_modal';
import { IDirectoryDeviceBehaviour, Permissions } from 'vigil-datamodel';
import { useNavigate } from 'react-router-dom';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextRoles } from '../../providers/provider_roles';
import { useTableData } from '../../hooks/hook_table_data';
import { TTActions } from 'tt-permissions';
import { ModalDeleteMany } from '../../components/modal_delete_many';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ModalDeviceBehaviourCreate } from './func_device_behaviour/modal_device_behaviour_create';

interface TabDeviceBehavioursProps { }

export const TabDeviceBehaviours: FC<TabDeviceBehavioursProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedBehavioursState, setSelectedBehaviours] = useState<IDirectoryDeviceBehaviour[]>([]);

  const { data: behaviours, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryDeviceBehaviour>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryDeviceBehaviours({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function deleteBehaviours(selectedBehaviours: IDirectoryDeviceBehaviour[]) {
    setSelectedBehaviours(selectedBehaviours);
    toggleModalDelete();
  }

  return (
    <div className='pt-2 h-full w-full'>
      <ModalDeviceBehaviourCreate
        isOpen={isOpenModalCreate}
        toggle={toggleModalCreate}
        onSubmit={forceRefetch}
      />
      <ModalDeleteMany
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='deviceBehaviours'
        data={selectedBehavioursState.map(behaviour => ({ uuid: behaviour.uuid, label: behaviour.name }))}
        onSubmit={forceRefetch}
      />

      <Table<IDirectoryDeviceBehaviour>
        className='h-full flex flex-col drop-shadow'
        name='Device Behaviours'
        data={behaviours}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return <div>{item.name}</div>;
            },
          },
          {
            id: 'deviceCount',
            header: 'Devices Linked',
            sortable: true,
            value(item) {
              return <div>{item.deviceCount}</div>;
            },
          },
          {
            id: 'siteCount',
            header: 'Sites Linked',
            sortable: true,
            value(item) {
              return <div>{item.siteCount}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />Add new Device Behaviour</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RDeviceBehaviour),
            onClick: () => toggleModalCreate(),
          }
        ]}

        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RDeviceBehaviour),
            onClick: (selectedItems) => deleteBehaviours(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RDeviceBehaviour),
            onClick: (selectedItems) => deleteBehaviours(selectedItems)
          }
        ]}
      />
    </div>
  );
}; 