import { useState } from "react";
import { DeviceBehaviourAdoptSiteSpecific } from "vigil-datamodel";

interface BehaviourAdoptSiteSpecificProps {
  behaviour?: DeviceBehaviourAdoptSiteSpecific;
  setBehaviour: (behaviour: DeviceBehaviourAdoptSiteSpecific) => void;
  setValidateForm: (validateForm: string[]) => void;
}

export const BehaviourAdoptSiteSpecificComponent: React.FC<BehaviourAdoptSiteSpecificProps> = (props) => {
  const [stateEnabled, setEnabled] = useState(props.behaviour?.enabled || false);

  /* Functions */
  function toggleEnabled() {
    setEnabled(!stateEnabled);
  }

  return (
    <div className="flex flex-wrap">
      <label className="p-0 label cursor-pointer max-w-fit">
        <input type="checkbox" checked={stateEnabled} onChange={toggleEnabled} className="checkbox checkbox-sm" />
        <div className="mx-0.5"></div>
        <span className="label-text font-semibold">Adopt Site Specific Device Behaviour</span>
      </label>
    </div>
  )
}