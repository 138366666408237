import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkOrganizationDevices } from "./bean_link_organization_device";
import { deviceStateLogs } from "./bean_device_state_log";
import { deviceActivityLogs } from "./bean_device_activity_log";
import { linkDeviceUserStatusses } from "./bean_link_device_user_status";

export const devices = sqliteTable('devices', {
  ...schemaBase(),
  serial: text('serial').notNull(),
  imei0: text('imei0').notNull(),
  imei1: text('imei1'),
});

export const devicesRelations = relations(devices, ({ many }) => ({
  devicesToUserStatuses: many(linkDeviceUserStatusses),
  devicesToOrganizations: many(linkOrganizationDevices),
  activityLogs: many(deviceActivityLogs),
  stateLogs: many(deviceStateLogs),
}));