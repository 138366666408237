import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { StatusLoading } from "../../../components/status_loading";
import { useCaller } from "../../../hooks/hook_caller";
import { ContextOrganization } from "../../../providers/provider_organization";
import { ISiteSelect } from "vigil-datamodel";

interface ModalSiteUpdateProps extends ModalProps {
  uuidSite: string;
  onSubmit?: () => Promise<void>;
}

export const ModalSiteUpdate: React.FC<ModalSiteUpdateProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);

  /* Lifecycle */
  const [changedSite, setChangedSite] = useState<ISiteSelect | null>(null);

  const currentSite = useCaller({
    callback: async () => {
      if (!organization.data) throw new Error('Organization not found');
      const site = await vigil.functions.findOneOnOrganization({ type: 'sites', uuidOrganization: organization.data.uuid, uuid: props.uuidSite });
      if (!site) throw new Error('Site not found');
      setChangedSite(site);
      return site;
    },
    dependencies: { background: [], normal: [props.uuidSite] },
  });

  const setSite = useCaller({
    callback: async () => {
      if (!organization.data) throw new Error('Organization not found');
      if (!changedSite) throw new Error('Site not found');
      await vigil.functions.updateOneOnOrganization({
        type: 'sites',
        uuidOrganization: organization.data!.uuid,
        uuid: changedSite.uuid,
        data: changedSite
      })
      props.onSubmit && await props.onSubmit();
      props.toggle();
    },
    doNotRunImmediate: true,
    onCalled: () => currentSite.call(true)
  });

  /* Validation */
  function validateNewSiteName() {
    if (!changedSite || !changedSite.name) return [];
    return validate(changedSite.name, [VALIDATORS.length('Site name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!changedSite || !changedSite.name) { required.push('Site name is required') }
    return [
      ...required,
      ...validateNewSiteName(),
    ];
  }

  /* UI Updates */
  function onChangeSiteName(event: React.ChangeEvent<HTMLInputElement>) {
    setChangedSite({ ...changedSite!, name: event.target.value });
  }

  function disableSubmitButton() {
    if (!currentSite.result || !changedSite) return true;
    if (validateForm().length > 0) return true;
    if (JSON.stringify(currentSite.result) === JSON.stringify(changedSite)) return true;
    return false;
  }

  if (!currentSite.result || currentSite.loading || !changedSite) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Update Site</h3>
      <InputText labelText='Site name' value={changedSite.name} onChange={onChangeSiteName} errors={validateNewSiteName()} > </InputText>
      {currentSite.error && <StatusAlert type='alert-error' message={currentSite.error} />}
      {setSite.error && <StatusAlert type='alert-error' message={setSite.error} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Update' loading={setSite.loading} disabled={disableSubmitButton()} type='btn-primary' onClick={async () => setSite.call()}></InputButton>
      </div>
    </Modal>
  )
}