import { ICreator } from "./type_creator";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { deviceBehaviourInstances } from "./bean_device_behaviour_instance";
import { relations } from "drizzle-orm";

export enum DeviceBehaviourInstanceLogType {
  Generic = 'generic',
}

interface IDeviceBehaviourInstanceLogBase { uuidDeviceBehaviourLogParent?: string }
export type IDeviceBehaviourInstanceLogGeneric = IDeviceBehaviourInstanceLogBase & {
  tags: string[],
  data: Record<string, any>,
}

export type DeviceBehaviourInstanceLogTypeData = (
  | { type: DeviceBehaviourInstanceLogType.Generic, data: IDeviceBehaviourInstanceLogGeneric }
)

export const deviceBehaviourInstanceLogs = sqliteTable('device_behaviour_instance_logs', {
  ...schemaBase(),
  uuidDeviceBehaviourInstance: text('uuid_device_behaviour_instance').notNull().references(() => deviceBehaviourInstances.uuid),
  creator: customJsonColumn<ICreator>('creator').notNull(),
  type: text('type', { enum: Object.values(DeviceBehaviourInstanceLogType) as [DeviceBehaviourInstanceLogType] }).notNull(),
  data: customJsonColumn<DeviceBehaviourInstanceLogTypeData>('data').notNull(),
});

export const deviceBehaviourInstanceLogsRelations = relations(deviceBehaviourInstanceLogs, ({ one }) => ({
  deviceBehaviourInstance: one(deviceBehaviourInstances, {
    fields: [deviceBehaviourInstanceLogs.uuidDeviceBehaviourInstance],
    references: [deviceBehaviourInstances.uuid],
  }),
}));
