import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { devices } from "./bean_device";
import { relations } from "drizzle-orm";

export enum DeviceActivityLogType {
  UserLogin = 'userLogin',
  UserLogout = 'userLogout',
  OrgLogin = 'orgLogin',
  OrgLogout = 'orgLogout',
  APKVersion = 'apkVersion',
  APPVersion = 'appVersion',
  GenericText = 'genericText',
}

export interface IDeviceActivityLogUserLoginLogout {
  userUuid: string,
}

export interface IDeviceActivityLogOrgLoginLogout {
  orgUuid: string,
}

export interface IDeviceActivityLogVersionUpdate {
  version: number,
}

export interface IDeviceActivityLogGenericText {
  title: string,
  description: string,
  tags: string[],
  data: Record<string, any>,
}

export const deviceActivityLogs = sqliteTable('device_activity_logs', {
  ...schemaBase(),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  type: text('type', { enum: Object.values(DeviceActivityLogType) as [DeviceActivityLogType] }).notNull(),
  data: customJsonColumn<IDeviceActivityLogUserLoginLogout | IDeviceActivityLogOrgLoginLogout | IDeviceActivityLogVersionUpdate | IDeviceActivityLogGenericText>('data').notNull(),
});

export const deviceActivityLogsRelations = relations(deviceActivityLogs, ({ one }) => ({
  device: one(devices, {
    fields: [deviceActivityLogs.uuidDevice],
    references: [devices.uuid],
  }),
}));