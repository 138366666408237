
import { integer, sqliteTable,  text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { deviceBehaviours, IDeviceBehaviour } from "./bean_device_behaviour";
import { deviceBehaviourInstanceLogs } from "./bean_device_behaviour_instance_log";

export const deviceBehaviourInstances = sqliteTable('device_behaviour_instances', {
  ...schemaBase(),
  dateTimeStart: integer('date_time_start', { mode: 'timestamp' }).notNull(),
  dateTimeEnd: integer('date_time_end', { mode: 'timestamp' }).notNull(),
  uuidDeviceBehaviour: text('uuid_device_behaviour').notNull().references(() => deviceBehaviours.uuid),
  deviceBehaviourSnapshot: customJsonColumn<IDeviceBehaviour>('device_behaviour').notNull(),
});

export const deviceBehaviourInstancesRelations = relations(deviceBehaviourInstances, ({ one, many }) => ({
  deviceBehaviourInstanceLogs: many(deviceBehaviourInstanceLogs),
  deviceBehaviour: one(deviceBehaviours, {
    fields: [deviceBehaviourInstances.uuidDeviceBehaviour],
    references: [deviceBehaviours.uuid],
  }),
}));