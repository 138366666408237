import { FC, useContext, useState } from 'react';
import { ModalDeviceLink } from './func_device/modal_device_link';
import { useModal } from '../../hooks/hook_modal';
import { IDirectoryDevice, Permissions } from 'vigil-datamodel';
import { useNavigate } from 'react-router-dom';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextRoles } from '../../providers/provider_roles';
import { DevicesTable } from '../shared/devices_table';
import { useTableData } from '../../hooks/hook_table_data';
import { TTActions } from 'tt-permissions';
import { ModalDeleteMany } from '../../components/modal_delete_many';
import { ROUTES } from '../../router/routes';

interface TabDevicesProps { }

export const TabDevices: FC<TabDevicesProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedDevicesState, setSelectedDevices] = useState<IDirectoryDevice[]>([]);

  const { data: devices, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryDevice>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryDevices({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function deleteDevices(selectedDevice: IDirectoryDevice[]) {
    setSelectedDevices(selectedDevice);
    toggleModalDelete();
  }

  function navigateDevice(selectedDevice: IDirectoryDevice) {
    navigate(ROUTES.ROUTE_HOME_DEVICE.replace(':uuid', selectedDevice.uuid))
  }

  return (
    <div className='pt-2 h-full w-full'>
      <ModalDeviceLink isOpen={isOpenModalCreate} toggle={toggleModalCreate} />
      <ModalDeleteMany
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='devices'
        data={selectedDevicesState.map(device => ({ uuid: device.uuid, label: device.uuid }))}
        onSubmit={forceRefetch}
      />

      <DevicesTable
        devices={devices}
        loading={loading}
        error={error}
        onDeviceClick={navigateDevice}
        onFiltersChange={handleFiltersChange}
        onSortingChange={handleSortingChange}
        tablePagingSelected={paging}
        onPagingChange={handlePagingChange}
        selectable={true}
        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />Link Device</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RDevice),
            onClick: () => toggleModalCreate(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RDevice),
            onClick: (selectedItems) => deleteDevices(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RDevice),
            onClick: (selectedItems) => deleteDevices(selectedItems)
          }
        ]}
      />
    </div>
  );
}; 