import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { devices } from "./bean_device";
import { deviceBehaviours } from "./bean_device_behaviour";

export const linkDeviceBehaviourDevices = sqliteTable('link_device_behaviour_devices', {
  ...schemaBase(),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  uuidDeviceBehaviour: text('uuid_device_behaviour').notNull().references(() => deviceBehaviours.uuid),
});

export const linkDeviceBehaviourDevicesRelations = relations(linkDeviceBehaviourDevices, ({ one }) => ({
  device: one(devices, {
    fields: [linkDeviceBehaviourDevices.uuidDevice],
    references: [devices.uuid],
  }),
  deviceBehaviour: one(deviceBehaviours, {
    fields: [linkDeviceBehaviourDevices.uuidDeviceBehaviour], 
    references: [deviceBehaviours.uuid],
  }),
}));
