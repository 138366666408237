import { TTFunction } from "tt-coms";

export type DeleteManyOnOrganization = 'users' | 'sites' | 'beacons' | 'devices' | 'deviceBehaviours' | 'events' | 'organizationRoles' | 'organizationRoleUserInvites' | 'actions' | 'reports';

export function createDeleteManyOnOrganization() {
  const baseFunction = TTFunction<{ type: DeleteManyOnOrganization; uuidOrganization: string; uuids: string[] }, void>();

  return async function <T extends DeleteManyOnOrganization>(input: { type: T, uuidOrganization: string, uuids: string[] }): Promise<void> {
    return baseFunction(input) as Promise<void>;
  }
}
