import { useContext } from "react";
import { ContextOrganization } from "../providers/provider_organization";
import { ContextVigilClient } from "../providers/provider_vigil_client";
import { InputButton } from "./input_button";
import { ModalProps, Modal } from "./modal";
import { StatusAlert } from "./status_alert";
import { useCaller } from "../hooks/hook_caller";
import { DeleteManyOnOrganization } from "vigil-datamodel/src/type_delete_many";

interface ModalDeleteManyProps extends ModalProps {
  type: DeleteManyOnOrganization;
  data: { uuid: string, label: string }[];
  extraComponents?: React.ReactNode;
  onSubmit?: () => Promise<void>;
}

export const ModalDeleteMany: React.FC<ModalDeleteManyProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { error, loading, call } = useCaller({
    callback: async () => {
      if (!organization.data) throw new Error('Organization not found');
      await vigil.functions.deleteManyOnOrganization({ type: props.type, uuidOrganization: organization.data.uuid, uuids: props.data.map(d => d.uuid) });
    },
    doNotRunImmediate: true,
    onCalled: async () => {
      props.onSubmit && await props.onSubmit();
      props.toggle();
    }
  });

  const typeSingular = props.type.toLowerCase().slice(0, -1);
  const typePlural = props.type.toLowerCase();
  const typeCapitalizedSingular = props.type.charAt(0).toUpperCase() + props.type.slice(1).toLowerCase().slice(0, -1);
  const typeCapitalizedPlural = props.type.charAt(0).toUpperCase() + props.type.slice(1).toLowerCase();

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">{props.data.length > 1 ? 'Delete ' + typeCapitalizedPlural : 'Delete ' + typeCapitalizedSingular}</h3>
      <div>Are you sure you want to delete the selected {props.data.length > 1 ? typePlural : typeSingular}? This will:</div>
      <ul className="list-disc list-inside">
        <li className="font-semibold">Delete the following {props.data.length > 1 ? typePlural : typeSingular}:</li>
        <ul className="list-decimal list-inside pl-6">
          {props.data.map(data => <li key={data.uuid}>{data.label}</li>)}
        </ul>
        {props.extraComponents}
        <li>Delete the {props.data.length > 1 ? typePlural : typeSingular} <b>PERMANENTLY</b>, and cannot be undone.</li>
      </ul>
      {error && <StatusAlert type='alert-error' message={error} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Delete' loading={loading} disabled={false} type='btn-error' onClick={call}></InputButton>
      </div>
    </Modal>
  )
}
