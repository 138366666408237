import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '../../components/table/table';
import { IDirectoryDeviceActivityLog } from 'vigil-datamodel';
import { TTuuid } from 'tt-uuid';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useTableData } from '../../hooks/hook_table_data';
import { DeviceActivityLogType, IDeviceActivityLogUserLoginLogout, IDeviceActivityLogVersionUpdate } from 'vigil-datamodel/src/bean_device_activity_log';

interface ScreenHomeDeviceLogsActivityProps { }

export const ScreenHomeDeviceLogsActivity: React.FC<ScreenHomeDeviceLogsActivityProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidDevice = params['uuid'] || '';

  const { data, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange } = useTableData<IDirectoryDeviceActivityLog>({
    fetchData: async ({ filters, sorting, paging }) => {
      const response = await vigil.functions.deviceActivityLogs({
        filters: [
          { id: 'uuidDevice', query: 'equals', value: uuidDevice },
          ...filters,
        ],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [uuidDevice]
  });

  return (
    <div className='flex flex-col h-full'>
      <Table<IDirectoryDeviceActivityLog>
        className='h-full flex flex-col drop-shadow my-2'
        name='Device Activity Logs'
        data={data}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'type',
            header: 'Activity Type',
            sortable: true,
            value: (item) => <div>{item.type}</div>,
          },
          {
            id: 'uuid',
            header: 'Date',
            sortable: true,
            value: (item) => {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'data',
            header: 'Details',
            sortable: false,
            value: (item) => {
              if (item.type === 'userLogin' || item.type === 'userLogout') {
                const user = item.user!;
                return (
                  <div>
                    <div>First Name: {user?.firstName}</div>
                    <div>Last Name: {user?.lastName}</div>
                  </div>
                );
              }
              if (item.type === DeviceActivityLogType.APKVersion) {
                const versionData = item.data as IDeviceActivityLogVersionUpdate;
                return <div>Version APK: {versionData.version}</div>;
              }
              if (item.type === DeviceActivityLogType.APPVersion) {
                const versionData = item.data as IDeviceActivityLogVersionUpdate;
                return <div>Version APP: {versionData.version}</div>;
              }
              return <div>{JSON.stringify(item.data)}</div>;
            },
          },
        ]}
        filtersOptions={[
          new DateFilter('uuid', 'Date', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
};
